import React from "react";
import { Link, graphql } from "gatsby";
import Seo from "../components/Seo";
import { productHolder } from "./product.module.scss";
import Slideshow from "../components/Slideshow";
import Tags from "../components/Tags";

export default function ProductPage({ pageContext, data }) {
  const { title } = pageContext;
  const { longDescription, specifications } = data.wpProduct.productInformation;
  const { tags, categories } = data.wpProduct;

  const { productImage } = data.wpProduct.productInformation;
  const { image1, image2, image3, image4, image5, image6, image7, image8 } =
    data.wpProduct.productGallery;

  const images = [
    productImage,
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
  ].filter((image) => image !== null);

  const parentCategories = categories.nodes.filter(
    (category) => category.parentId === null
  );
  const childrenCategories = categories.nodes.filter(
    (category) => category.parentId !== null
  );

  return (
    <article className={productHolder}>
      {images.length > 0 && <Slideshow images={images} />}
      <div className="contentHolder">
        <div className={`${images.length > 0 ? "withImages" : ""} content`}>
          <ul className="breadcrumb">
            {parentCategories.map((category, index) => {
              return (
                <li key={category.slug}>
                  <Link to={`/category/${category.slug}`} title={category.name}>
                    {category.name}
                  </Link>
                  {childrenCategories.length > 0 && (
                    <span className="slash"> / </span>
                  )}
                  {childrenCategories.length > 0 && (
                    <ul className="childrenCategories">
                      {childrenCategories.map((childCategory, index) => (
                        <li key={childCategory.slug}>
                          <Link
                            to={`/category/${childCategory.slug}`}
                            title={childCategory.name}
                          >
                            {childCategory.name}
                          </Link>
                          {index < childrenCategories.length - 1 && ", "}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
          <h1>{title}</h1>
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: longDescription }}
          />
          {specifications && (
            <>
              <h3>Specifications</h3>
              <div
                className="specifications"
                dangerouslySetInnerHTML={{ __html: specifications }}
              />
            </>
          )}

          {tags.nodes.length > 0 && <Tags tags={tags} />}
        </div>
      </div>
    </article>
  );
}

export const Head = ({ pageContext: { title, shortDescription } }) => (
  <Seo
    title={`${title} - available for hire in London`}
    description={shortDescription}
  />
);

export const data = graphql`
  query ProductPage($slug: String!) {
    wpProduct(slug: { eq: $slug }) {
      title
      slug
      tags {
        nodes {
          slug
          name
        }
      }
      categories {
        nodes {
          slug
          name
          parentId
        }
      }
      productInformation {
        optionalExtras
        shortDescription
        longDescription
        specifications
        productImage {
          title
          slug
          id
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1000
                height: 610
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
      productGallery {
        image1 {
          title
          slug
          id
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1000
                height: 610
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        image2 {
          title
          slug
          id
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1000
                height: 610
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        image3 {
          title
          slug
          id
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1000
                height: 610
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        image4 {
          title
          slug
          id
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1000
                height: 610
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        image5 {
          title
          slug
          id
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1000
                height: 610
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        image6 {
          title
          slug
          id
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1000
                height: 610
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        image7 {
          title
          slug
          id
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1000
                height: 610
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        image8 {
          title
          slug
          id
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1000
                height: 610
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        image9 {
          title
          slug
          id
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1000
                height: 610
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        image10 {
          title
          slug
          id
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1000
                height: 610
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`;
