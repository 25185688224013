import { GatsbyImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";
import { slideshowHolder } from "./Slideshow.module.scss";
import { motion } from "framer-motion";

const slideshowVariants = {
  initial: {
    opacity: 0,
  },
  nextIn: {
    opacity: 1,
    x: [50, 0],
    transition: {
      delay: 0.3,
      duration: 0.6,
      type: "tween",
      ease: "easeInOut",
    },
  },
  prevIn: {
    x: [-50, 0],
    opacity: 1,
    transition: {
      duration: 0.6,
      delay: 0.3,
      type: "tween",
      ease: "easeInOut",
    },
  },
  nextExit: {
    opacity: 0,
    x: -50,
    transition: {
      duration: 0.6,
      type: "tween",
      ease: "easeInOut",
    },
  },
  prevExit: {
    x: 50,
    opacity: 0,
    transition: {
      duration: 0.6,
      type: "tween",
      ease: "easeInOut",
    },
  },
};

const SlideshowProgress = ({ currentImage, imageCount, time }) => {
  const variants = {
    active: (time) => {
      return {
        "--progress": ["0%", "100%"],
        transition: {
          duration: time / 1000,
          ease: "linear",
        },
      };
    },
    inactive: {
      "--progress": "0%",
    },
    shown: {
      "--progress": "99.9%",
      transition: {
        duration: 0.1,
        ease: "linear",
      },
    },
  };

  return (
    <div className="progressHolder">
      <ul>
        {Array.from({ length: imageCount }, (_, i) => {
          let animate = "inactive";
          if (i === currentImage.index) {
            animate = "active";
          }
          if (currentImage.index > i) {
            animate = "shown";
          }

          return (
            <motion.li
              key={i}
              className={i === currentImage.index ? "active" : ""}
              variants={variants}
              initial="inactive"
              animate={animate}
              custom={time}
            ></motion.li>
          );
        })}
      </ul>
    </div>
  );
};

export default function Slideshow({ images }) {
  const [currentImage, setCurrentImage] = useState({
    index: 0,
    direction: "next",
  });

  const slideshowInterval = 5000;

  const nextImage = () => {
    setCurrentImage((currentImage) => {
      if (currentImage.index === images.length - 1) {
        return { index: 0, direction: "next" };
      } else {
        return { index: currentImage.index + 1, direction: "next" };
      }
    });
  };

  const prevImage = () => {
    setCurrentImage((currentImage) => {
      if (currentImage.index === 0) {
        return { index: images.length - 1, direction: "prev" };
      } else {
        return { index: currentImage.index - 1, direction: "prev" };
      }
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextImage();
    }, slideshowInterval);

    if (images.length === 1) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  });

  return (
    <div className={slideshowHolder}>
      {images.length > 1 && (
        <>
          <button className="previous" onClick={prevImage}>
            Previous
          </button>

          <SlideshowProgress
            currentImage={currentImage}
            imageCount={images.length}
            time={5000}
          />
        </>
      )}

      <ul className="slides">
        {images.map((img, key) => (
          <motion.li
            className="slide"
            variants={slideshowVariants}
            key={key}
            initial="initial"
            animate={
              key === currentImage.index
                ? currentImage.direction + "In"
                : currentImage.direction + "Exit"
            }
          >
            <GatsbyImage
              key={img.title}
              image={img.localFile.childImageSharp.gatsbyImageData}
              alt={img.title}
            />
          </motion.li>
        ))}
      </ul>
      {images.length > 1 && (
        <button className="next" onClick={nextImage}>
          Next
        </button>
      )}
    </div>
  );
}
