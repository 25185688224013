import { Link } from "gatsby";
import React from "react";
import { tagsHolder } from "./Tags.module.scss";

export default function Tags({ tags }) {
  return (
    <div className={`${tagsHolder} tagsHolder`}>
      <h3>Tags</h3>
      <ul>
        {tags.nodes.map((tag) => (
          <li key={tag.slug}>
            <Link
              to={`/tag/${tag.slug}`}
              title={`Other products tagged "${tag.name}"`}>
              {tag.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
